import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'; // Importa BrowserRouter, Route y Routes
import './App.css';
import Navbar from './components/Navbar.js';
import Services from './components/Services';
import Home from './components/Home.js';
import About from './components/About.js';
import Demo from './components/Demo';
import Contact from './components/Contact.js';
import Footer from './components/Footer.js';
import Proyectos from './components/Proyectos/Proyectos';
import Proyecto1 from './components/Proyectos/Proyecto1';
import Proyecto2 from './components/Proyectos/Proyecto2';
import ScrollToTop from './ScrollToTop';


function App() {
  return (
    <Router>
      <ScrollToTop />
      <div>
        <Navbar /> {/*Menú de la página*/}
        <Routes>
          <Route path="/" element={<Home />} /> {/*Home o Inicio*/}
          <Route path="/services" element={<Services />} /> {/*Services o Servicios*/}
          <Route path="/about" element={<About />} /> {/*Aboutmo Sobre Nosotros*/}
          <Route path="/demo" element={<Demo />} />
          <Route path="/contact" element={<Contact />} /> {/*Contact o Contacto*/}
          <Route path="/proyectos" element={<Proyectos />} />
          <Route path="/proyectos/1" element={<Proyecto1 />} />
          <Route path="/proyectos/2" element={<Proyecto2 />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  )
}



export default App;