import React, { useState } from 'react';
import './Demo.css';

function Demo() {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [items, setItems] = useState([]);
    const [newItem, setNewItem] = useState('');

    const handleLogin = (e) => {
        e.preventDefault();
        // Simulamos el login solo con fines de demo.
        setIsLoggedIn(true);
    };

    const handleLogout = () => {
        setIsLoggedIn(false);
        setEmail('');
        setPassword('');
        setItems([]);
    };

    const handleAddItem = () => {
        if (newItem.trim() !== '') {
            setItems([...items, newItem]);
            setNewItem('');
        }
    };

    const handleDeleteItem = (index) => {
        setItems(items.filter((_, i) => i !== index));
    };

    return (
        <div className="demo-container">
            {!isLoggedIn ? (
                <div className="login-box">
                    <h2 className="login-title">Iniciar Sesión</h2>
                    <p className="login-info">Bienvenido al demo, por favor inicie sesión para ver más.</p>
                    <form onSubmit={handleLogin} className="login-form">
                        <label htmlFor="email" className="login-label">Correo Electrónico</label>
                        <input 
                            type="email" 
                            id="email" 
                            className="login-input" 
                            placeholder="Correo electrónico"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />
                        
                        <label htmlFor="password" className="login-label">Contraseña</label>
                        <input 
                            type="password" 
                            id="password" 
                            className="login-input" 
                            placeholder="Contraseña"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                        />

                        <button type="submit" className="login-button">Ingresar</button>
                    </form>
                </div>
            ) : (
                <div className="crud-box">
                    <h2 className="crud-title">CRUD Demo</h2>
                    <p className="crud-info">Agrega o elimina elementos para ver cómo funciona un CRUD básico.</p>
                    <div className="crud-form">
                        <input
                            type="text"
                            placeholder="Nuevo Elemento"
                            value={newItem}
                            onChange={(e) => setNewItem(e.target.value)}
                            className="crud-input"
                        />
                        <button onClick={handleAddItem} className="crud-button">Agregar</button>
                    </div>
                    <ul className="crud-list">
                        {items.map((item, index) => (
                            <li key={index} className="crud-item">
                                {item}
                                <button onClick={() => handleDeleteItem(index)} className="crud-delete-button">Eliminar</button>
                            </li>
                        ))}
                    </ul>
                    <button onClick={handleLogout} className="logout-button">Cerrar Sesión</button>
                </div>
            )}
        </div>
    );
}

export default Demo;
